import { useI18n } from "@vaultinum/app-sdk";
import logo from "../assets/images/logo-app-white.png";
import { Translation } from "../i18n";

const STANDARD = "NF Z67-147";

export default function Footer() {
    const { translation } = useI18n<Translation>();

    return (
        <footer className="h-12 w-full flex justify-center items-center bg-primary text-white gap-4 text-lg">
            <a
                href={process.env.REACT_APP_EVIDENCY_USER_AGREEMENT_URL}
                target="_blank"
                className="hover:underline hover:cursor-pointer"
                rel="noreferrer noopener"
            >
                {translation.information}
            </a>
            <span>|</span>
            <div className="flex items-center gap-1">
                <span>{translation.platformDevelopedInPartnershipWith}</span>
                <img src={logo} alt="Vaultinum" className="w-32" />
            </div>
            <span>|</span>
            <div className="flex items-center gap-1">
                <span>{translation.compliant}</span>
                <a href={process.env.REACT_APP_NFZ67147} target="_blank" className="hover:underline hover:cursor-pointer" rel="noreferrer noopener">
                    {STANDARD}
                </a>
            </div>
        </footer>
    );
}
